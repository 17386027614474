


































import { Component, Vue } from 'vue-property-decorator';
import { api } from '@/api';
import { appName } from '@/env';
import { readLoginError, readLoginErrorCode, readLoginErrorText } from '@/store/main/getters';
import { dispatchLogIn } from '@/store/main/actions';

@Component
export default class Login extends Vue {
  public email: string = '';
  public password: string = '';
  public appName = appName;
  public errMsg = '';

  public get loginError() {
    const result = readLoginError(this.$store);
    if (result) {
      const code = readLoginErrorCode(this.$store);
      if (code == 400) {
        this.errMsg = 'Неверный логин и\\или пароль';
        return true;
      }
      this.errMsg = 'Не удалось войти. Код ошибки: ' + code;
      this.errMsg = this.errMsg + '\n' + readLoginErrorText(this.$store);
      return true;
    }
    this.errMsg = '';
    return  false;
  }

  public submit() {
    dispatchLogIn(this.$store, {username: this.email, password: this.password});
  }
}
